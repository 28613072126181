import * as sc from 'styled-components';
import * as t from 'io-ts';

export const DefaultThemeCodec = t.type({
  id: t.string,
  type: t.union([t.literal('light'), t.literal('dark')]),
  textColor: t.string,
  backgroundColor: t.string,
  borderColor: t.string,
  navbarTitleEffect: t.type({
    type: t.string,
  }),
  navbar: t.type({
    backgroundColor: t.string,
    dropdown: t.type({
      hover: t.type({
        backgroundColor: t.string,
        textColor: t.string,
      }),
    }),
  }),
  card: t.type({
    backgroundColor: t.string,
    header: t.type({
      backgroundColor: t.string,
    }),
  }),
  listGroupItem: t.type({
    backgroundColor: t.string,
    hover: t.type({
      backgroundColor: t.string,
      textColor: t.string,
    }),
    mutedTextColor: t.string,
  }),
  input: t.type({
    text: t.type({
      placeholder: t.type({
        textColor: t.string,
      }),
    }),
  }),
  modal: t.type({
    backgroundColor: t.string,
  }),
  security: t.type({
    card: t.type({
      backgroundColor: t.string,
    }),
  }),
  users: t.type({
    card: t.type({
      backgroundColor: t.string,
    }),
  }),
  tooltip: t.type({
    textColor: t.string,
    backgroundColor: t.string,
    shadowColor: t.string,
    fontSize: t.string,
  }),
  primaryColor: t.string,
  selectionColor: t.string,
  notSelectedColor: t.string,
  disabledColor: t.string,
  secondaryTextColor: t.string,
  logoColor: t.string,
  attentionColor: t.string,
  secondaryColor: t.string,
  darkPrimaryColor: t.string,
  boxShadowColor: t.string,
});

declare module 'styled-components' {
  type DefaultThemeType = t.TypeOf<typeof DefaultThemeCodec>;

  // We have to do it like this so that typescript can merge our type into the existing DefaultTheme interface
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends DefaultThemeType {}
}

export const lightMode: sc.DefaultTheme = {
  id: 'light mode',
  type: 'light',
  textColor: '#000000',
  backgroundColor: '#ffffff',
  borderColor: 'rgba(0, 0, 0, 0.125)',
  navbarTitleEffect: {
    type: 'none',
  },
  navbar: {
    backgroundColor: '#dfdfdf',
    dropdown: {
      hover: {
        backgroundColor: '#dfdfdf',
        textColor: '#000000',
      },
    },
  },
  card: {
    backgroundColor: '#ffffff',
    header: {
      backgroundColor: '#dfdfdf',
    },
  },
  listGroupItem: {
    backgroundColor: 'rgba(245, 247, 249)',
    hover: {
      backgroundColor: '#dfdfdf',
      textColor: '#000000',
    },
    mutedTextColor: '#6B727B',
  },
  input: {
    text: {
      placeholder: {
        textColor: '#6c757d',
      },
    },
  },
  modal: {
    backgroundColor: '#ffffff',
  },
  security: {
    card: {
      backgroundColor: '#ffffff',
    },
  },
  users: {
    card: {
      backgroundColor: '#ffffff',
    },
  },
  tooltip: {
    textColor: '#000000',
    backgroundColor: '#dfdfdf',
    shadowColor: '#000000',
    fontSize: '0.75em',
  },
  primaryColor: '#007bc7',
  selectionColor: 'rgba(0, 0, 0, 0.1)',
  notSelectedColor: 'rgba(0,0,0,0)',
  disabledColor: '#e9ecef',
  secondaryTextColor: '#555555',
  logoColor: '#37b1ff',
  attentionColor: '#c84630',
  secondaryColor: '#5a5a5a',
  darkPrimaryColor: '#004c99',
  boxShadowColor: 'rgba(0, 123, 199, 0.5)',
};

export const darkMode: sc.DefaultTheme = {
  id: 'dark mode',
  type: 'dark',
  textColor: '#e0e0e0',
  backgroundColor: '#000000',
  borderColor: 'rgba(255, 255, 255, 0.25)',
  navbarTitleEffect: {
    type: 'none',
  },
  navbar: {
    backgroundColor: '#202020',
    dropdown: {
      hover: {
        backgroundColor: '#202020',
        textColor: '#ffffff',
      },
    },
  },
  card: {
    backgroundColor: '#000000',
    header: {
      backgroundColor: '#202020',
    },
  },
  listGroupItem: {
    backgroundColor: 'rgba(35, 35, 35)',
    hover: {
      backgroundColor: '#202020',
      textColor: '#ffffff',
    },
    mutedTextColor: '#848A94',
  },
  input: {
    text: {
      placeholder: {
        textColor: '#938A82',
      },
    },
  },
  modal: {
    backgroundColor: '#000000',
  },
  security: {
    card: {
      backgroundColor: '#101010',
    },
  },
  users: {
    card: {
      backgroundColor: '#101010',
    },
  },
  tooltip: {
    textColor: '#ffffff',
    backgroundColor: '#202020',
    shadowColor: '#000000',
    fontSize: '0.75em',
  },
  primaryColor: '#007bc7',
  selectionColor: 'rgba(255, 255, 255, 0.1)',
  notSelectedColor: 'rgba(0,0,0,0)',
  disabledColor: '#161310',
  secondaryTextColor: '#5a5a5a',
  logoColor: '#37b1ff',
  attentionColor: '#c84630',
  secondaryColor: '#5a5a5a',
  darkPrimaryColor: '#003366',
  boxShadowColor: 'rgba(0, 123, 199, 0.5)',
};

export const clover: sc.DefaultTheme = {
  id: 'clover',
  type: 'light',
  textColor: '#000000',
  backgroundColor: '#ffffff',
  borderColor: 'rgba(0, 0, 0, 0.125)',
  navbarTitleEffect: {
    type: 'none',
  },
  navbar: {
    backgroundColor: '#dfdfdf',
    dropdown: {
      hover: {
        backgroundColor: '#dfdfdf',
        textColor: '#000000',
      },
    },
  },
  card: {
    backgroundColor: '#ffffff',
    header: {
      backgroundColor: '#dfdfdf',
    },
  },
  listGroupItem: {
    backgroundColor: 'rgba(245, 247, 249)',
    hover: {
      backgroundColor: '#dfdfdf',
      textColor: '#000000',
    },
    mutedTextColor: '#6b7b6d',
  },
  input: {
    text: {
      placeholder: {
        textColor: '#6c7d6f',
      },
    },
  },
  modal: {
    backgroundColor: '#ffffff',
  },
  security: {
    card: {
      backgroundColor: '#ffffff',
    },
  },
  users: {
    card: {
      backgroundColor: '#ffffff',
    },
  },
  tooltip: {
    textColor: '#000000',
    backgroundColor: '#dfdfdf',
    shadowColor: '#000000',
    fontSize: '0.75em',
  },
  primaryColor: '#008A09',
  selectionColor: 'rgba(0, 0, 0, 0.1)',
  notSelectedColor: 'rgba(0,0,0,0)',
  disabledColor: '#e9efea',
  secondaryTextColor: '#555555',
  logoColor: '#3eff37',
  attentionColor: '#c84630',
  secondaryColor: '#5a5a5a',
  darkPrimaryColor: '#055c00',
  boxShadowColor: 'rgba(0, 138, 9, 0.5)',
};

export const spooky: sc.DefaultTheme = {
  id: 'spooky',
  type: 'dark',
  textColor: '#e0e0e0',
  backgroundColor: '#000000',
  borderColor: 'rgba(255, 255, 255, 0.25)',
  navbarTitleEffect: {
    type: 'spooky',
  },
  navbar: {
    backgroundColor: '#202020',
    dropdown: {
      hover: {
        backgroundColor: '#202020',
        textColor: '#ffffff',
      },
    },
  },
  card: {
    backgroundColor: '#000000',
    header: {
      backgroundColor: '#202020',
    },
  },
  listGroupItem: {
    backgroundColor: 'rgba(35, 35, 35)',
    hover: {
      backgroundColor: '#202020',
      textColor: '#ffffff',
    },
    mutedTextColor: '#848A94',
  },
  input: {
    text: {
      placeholder: {
        textColor: '#938A82',
      },
    },
  },
  modal: {
    backgroundColor: '#000000',
  },
  security: {
    card: {
      backgroundColor: '#101010',
    },
  },
  users: {
    card: {
      backgroundColor: '#101010',
    },
  },
  tooltip: {
    textColor: '#ffffff',
    backgroundColor: '#202020',
    shadowColor: '#000000',
    fontSize: '0.75em',
  },
  primaryColor: '#c13e00',
  selectionColor: 'rgba(255, 255, 255, 0.1)',
  notSelectedColor: 'rgba(0,0,0,0)',
  disabledColor: '#161310',
  secondaryTextColor: '#5a5a5a',
  logoColor: '#37b1ff',
  attentionColor: '#c13e00',
  secondaryColor: '#5a5a5a',
  darkPrimaryColor: '#662a00',
  boxShadowColor: 'rgba(193, 62, 0, 0.5)',
};

export const christmas: sc.DefaultTheme = {
  id: 'christmas',
  type: 'light',
  textColor: '#0000FF', // Blue text color
  backgroundColor: '#FFFFFF', // White background color
  borderColor: 'rgba(0, 0, 255, 0.125)', // Light blue border color
  navbarTitleEffect: {
    type: 'none',
  },
  navbar: {
    backgroundColor: '#E0F7FA', // Light cyan background color
    dropdown: {
      hover: {
        backgroundColor: '#B3E5FC', // Light blue background color
        textColor: '#0000FF', // Blue text color
      },
    },
  },
  card: {
    backgroundColor: '#FFFFFF', // White background color
    header: {
      backgroundColor: '#E0F7FA', // Light cyan background color
    },
  },
  listGroupItem: {
    backgroundColor: 'rgba(240, 248, 255)', // Alice blue background color
    hover: {
      backgroundColor: '#B3E5FC', // Light blue background color
      textColor: '#0000FF', // Blue text color
    },
    mutedTextColor: '#6B727B', // Muted text color
  },
  input: {
    text: {
      placeholder: {
        textColor: '#6c757d', // Placeholder text color
      },
    },
  },
  modal: {
    backgroundColor: '#FFFFFF', // White background color
  },
  security: {
    card: {
      backgroundColor: '#FFFFFF', // White background color
    },
  },
  users: {
    card: {
      backgroundColor: '#FFFFFF', // White background color
    },
  },
  tooltip: {
    textColor: '#0000FF', // Blue text color
    backgroundColor: '#E0F7FA', // Light cyan background color
    shadowColor: '#0000FF', // Blue shadow color
    fontSize: '0.75em', // Font size
  },
  primaryColor: '#0000FF', // Blue primary color
  selectionColor: 'rgba(0, 0, 255, 0.1)', // Light blue selection color
  notSelectedColor: 'rgba(0,0,0,0)', // Not selected color
  disabledColor: '#E0E0E0', // Disabled color
  secondaryTextColor: '#555555', // Secondary text color
  logoColor: '#0000FF', // Blue logo color
  attentionColor: '#FF0000', // Red attention color
  secondaryColor: '#5a5a5a', // Secondary color
  darkPrimaryColor: '#00008B', // Dark blue primary color
  boxShadowColor: 'rgba(0, 0, 255, 0.5)', // Blue box shadow color
};

export const themesById: Record<string, sc.DefaultTheme> = {
  [lightMode.id]: lightMode,
  [darkMode.id]: darkMode,
  [clover.id]: clover,
  [spooky.id]: spooky,
  [christmas.id]: christmas,
};
