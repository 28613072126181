import * as React from 'react';
import styles from './Snowflakes.module.scss';
export const Snowflakes: React.FC = () => {
  const divs = Array(50).fill(<div className={styles.snow}>&#10052;</div>);

  return (
    <div className={styles.main}>
      <div className={styles.initialSnow}>{divs}</div>
    </div>
  );
};
