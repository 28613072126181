import * as React from 'react';
import { Button, ButtonGroup, ButtonGroupProps } from 'reactstrap';
import { lightMode, darkMode, clover, spooky, christmas } from '../../styles/theme';
import { useTheme } from '../../styles/useTheme';
import { useDecision } from '@optimizely/react-sdk';
import { styled } from 'styled-components';

export const ThemeSelector: React.FC<ButtonGroupProps> = (props) => {
  const [theme, setTheme] = useTheme();

  const [clover_theme_decision] = useDecision('clover_theme');
  const [spooky_theme_decision] = useDecision('spooky_theme');
  const [christmas_theme_decision] = useDecision('christmas_theme');

  return (
    <ButtonGroup {...props}>
      <StyledButton
        color="primary"
        outline={true}
        onClick={(e) => {
          e.stopPropagation();
          setTheme(lightMode.id);
        }}
        active={theme.id === lightMode.id}
      >
        Light
      </StyledButton>
      <StyledButton
        color="primary"
        outline={true}
        onClick={(e) => {
          e.stopPropagation();
          setTheme(darkMode.id);
        }}
        active={theme.id === darkMode.id}
      >
        Dark
      </StyledButton>
      {clover_theme_decision.enabled && (
        <StyledButton
          color="primary"
          outline={true}
          onClick={(e) => {
            e.stopPropagation();
            setTheme(clover.id);
          }}
          active={theme.id === clover.id}
        >
          Clover
        </StyledButton>
      )}
      {spooky_theme_decision.enabled && (
        <StyledButton
          color="primary"
          outline={true}
          onClick={(e) => {
            e.stopPropagation();
            setTheme(spooky.id);
          }}
          active={theme.id === spooky.id}
        >
          Spooky
        </StyledButton>
      )}
      {christmas_theme_decision.enabled && (
        <StyledButton
          color="primary"
          outline={true}
          onClick={(e) => {
            e.stopPropagation();
            setTheme(christmas.id);
          }}
          active={theme.id === christmas.id}
        >
          Christmas
        </StyledButton>
      )}
    </ButtonGroup>
  );
};

const StyledButton = styled(Button)`
  && {
    color: ${({ theme }) => theme.primaryColor};
    background-color: rgba(0, 0, 0, 0);
    border-color: ${({ theme }) => theme.primaryColor};
  }

  &&:hover {
    color: white;
    background-color: ${({ theme }) => theme.primaryColor};
  }

  &&.active {
    color: white;
    background-color: ${({ theme }) => theme.primaryColor};
  }
`;
