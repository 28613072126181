import * as React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './store/history';
import { Routes } from './Routes';
import styled, { ThemeProvider } from 'styled-components';
import { useTheme } from './styles/useTheme';
import { spooky } from './styles/theme';
import { christmas } from './styles/theme';
import { GhostParticles } from './components/seasonal/GhostParticles';
import { useFlashLight } from './components/seasonal/useFlashlight';
import { OptimizelyProvider } from './OptimizelyProvider';
import { Snowflakes } from './components/seasonal/Snowflakes';

const App: React.FC = () => {
  const [theme] = useTheme();

  document.documentElement.style.setProperty('--primary', '#00ff00');

  return (
    <OptimizelyProvider>
      <ThemeProvider theme={theme}>
        <Root>
          <ConnectedRouter history={history}>
            <Routes />
          </ConnectedRouter>
        </Root>
      </ThemeProvider>
    </OptimizelyProvider>
  );
};

interface RootProps {
  children: React.ReactNode;
}

const Root: React.FC<RootProps> = (props) => {
  const [theme] = useTheme();
  const { FlashLight, updateFlashlightPosition } = useFlashLight();

  const isSpooky = theme.id === spooky.id;
  const isChristmas = theme.id === christmas.id;

  return (
    <>
      {isSpooky && <GhostParticles />}
      <StyledDiv onMouseMove={isSpooky ? updateFlashlightPosition : undefined}>
        {props.children}
      </StyledDiv>
      {isSpooky && (
        <>
          <FlashLight />
          <audio src="Creepy Hallow.mp3" autoPlay loop />
        </>
      )}
      {isChristmas && <Snowflakes />}
    </>
  );
};

const StyledDiv = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor};
  height: 100%;

  /* Override switch colors */
  & .custom-switch .custom-control-input:focus ~ .custom-control-label::before {
    border-color: ${({ theme }) => theme.primaryColor} !important;
  }

  &
    .custom-switch
    .custom-control-input:checked
    ~ .custom-control-label::before {
    border-color: ${({ theme }) => theme.primaryColor} !important;
    background-color: ${({ theme }) => theme.primaryColor} !important;
  }

  &
    .custom-switch
    .custom-control-input:active
    ~ .custom-control-label::before {
    background-color: ${({ theme }) => theme.primaryColor} !important;
    border-color: ${({ theme }) => theme.primaryColor} !important;
  }

  &
    .custom-switch
    .custom-control-input:focus:not(:checked)
    ~ .custom-control-label::before {
    border-color: ${({ theme }) => theme.primaryColor} !important;
  }

  &
    .custom-switch
    .custom-control-input-green:not(:disabled):active
    ~ .custom-control-label::before {
    background-color: ${({ theme }) => theme.primaryColor} !important;
    border-color: ${({ theme }) => theme.primaryColor} !important;
  }

  &
    .custom-checkbox
    .custom-control-input:active
    ~ .custom-control-label::before,
  &
    .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::before {
    background-color: ${({ theme }) => theme.primaryColor} !important;
  }

  /* Override button colors */
  & .btn-primary {
    background-color: ${({ theme }) => theme.primaryColor};
    border-color: ${({ theme }) => theme.primaryColor};
  }

  /* Override outlined button colors */
  & .btn-outline-primary {
    color: ${({ theme }) => theme.primaryColor};
    border-color: ${({ theme }) => theme.primaryColor};

    &.active {
      background-color: ${({ theme }) => theme.primaryColor} !important;
    }

    :hover {
      background-color: ${({ theme }) => theme.primaryColor};
    }
  }

  /* Override progress bar colors */
  & .progress-bar {
    background-color: ${({ theme }) => theme.primaryColor};
  }
`;

export default App;
